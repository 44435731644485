import axios from "axios";

export default async function updateReferral(payload: {
  email: string;
  enrollProfitRate?: number;
  addedReferralIds?: number[];
  removedReferralIds?: number[];
}): Promise<void> {
  const { data } = await axios.post("/admin/referral/update", payload);

  return data;
}
