import axios from "axios";
import { ApiPageResponse, ApiPageRequest } from "@admin/types/api";
import { CardStatistic } from "@admin/types/card";

export default async function fetchCardStatistics(
  payload: ApiPageRequest & {
    userEmails?: string[];
    bankIds?: number[];
  }
) {
  const { data } = await axios.post<ApiPageResponse<CardStatistic>>(
    "/admin/card/statistic",
    payload
  );

  return data;
}
