export enum Role {
  ROLE_SUPERADMIN = "ROLE_SUPERADMIN",
  ROLE_AGENT = "ROLE_AGENT",
  ROLE_TEAMLEAD = "ROLE_TEAMLEAD",
  ROLE_MEDIABUYER = "ROLE_MEDIABUYER",
  ROLE_SUPPORT = "ROLE_SUPPORT",
  ROLE_NETWORK = "ROLE_NETWORK",
  ROLE_ACCOUNT_MANAGER = "ROLE_ACCOUNT_MANAGER",
  ROLE_GHOST = "ROLE_GHOST",
  ROLE_OWNER = "ROLE_OWNER",
  ROLE_ACCOUNTANT = "ROLE_ACCOUNTANT",
  ROLE_FINANCE = "ROLE_FINANCE",
}
